<template>
  <div>
    <el-card class="custom-card condition blue active" shadow="never">
      <div slot="header" class="clearfix">
        <span>Information of Calendar</span>
      </div>
      <div>
        <el-card shadow="never" class="card-inside normal" v-if="loading">
          <el-row :gutter="15" type="flex" class="flex-wrap">
            <el-col :span="24" :md="12">
              <el-form-item label="Company name (ชื่อบริษัท)">
                <el-input v-model="form.companyName" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Address Of Client (ที่อยู่)">
                <el-input v-model="form.address" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Contact person name (ชื่อผู้ติดต่อ)">
                <el-input v-model="form.contactPerson" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Phone number (เบอร์โทร)">
                <el-input v-model="form.phone" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="form.companyType.standard == 99 ? 6 : 12">
              <el-form-item label="Standard (มาตรฐาน)">
                <el-select v-model="form.companyType.standard" disabled>
                  <template slot="empty">
                    <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                  </template>
                  <el-option
                    v-for="item in form.companyType.type == 1
                      ? optionsStandardFood
                      : form.companyType.company == 1
                      ? optionsStandardIsoOne
                      : optionsStandardIsoTwo"
                    :label="item.standardName"
                    :value="item.standardNum"
                    :key="item.standardNum"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="6" v-if="form.companyType.standard == 99">
              <el-form-item class="mg-b-less no-label">
                <el-input v-model="form.companyType.other" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="6">
              <el-form-item label="CAT No.">
                <el-input
                  v-model="form.companyType.catNo"
                  disabled
                  id="catNo"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24" :md="6">
              <el-form-item label="Audit cycle (รอบการตรวจติดตาม)">
                <el-select
                  v-model="form.companyType.auditCycle"
                  placeholder=""
                  disabled
                >
                  <el-option
                    v-for="item in optionsAuditCycle"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Auditor (ผู้ตรวจ)">
                <el-input v-model="form.auditorName" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Auditor (ผู้ตรวจ)">
                <el-input v-model="form.auditors.phone" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="6">
              <el-form-item label="Status (สถานะรอบการตรวจ)">
                <el-select
                  v-model="form.statusChecking"
                  clearable
                  :disabled="user.role == 50"
                >
                  <el-option
                    v-for="item in optionCalendar.statusChecking"
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="6">
              <el-form-item label="Customer Status (สถานะลูกค้า)">
                <el-select
                  v-model="form.customerStatus"
                  clearable
                  :disabled="user.role == 50"
                >
                  <el-option
                    v-for="item in optionCalendar.customerStatus"
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Note (เพิ่มเติม)">
                <el-input
                  v-model="form.more"
                  placeholder="Note"
                  clearable
                  @blur="form.more = form.more.trim()"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="Document (อัปโหลดเอกสารครั้งละไม่เกิน 25 MB)"
              >
                <el-upload
                  drag
                  multiple
                  action="#"
                  :auto-upload="false"
                  :on-change="
                    (file, fileList) => uploadDocuments(file, fileList)
                  "
                  :on-remove="handleRemoveDocuments"
                  list-type="picture"
                  :file-list="form.fileList"
                  :on-preview="(file) => perviewFile(file)"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div :gutter="15" class="is-flex ai-center js-between mg-t-4">
                <div>
                  <el-button
                    round
                    @click="$emit(`closeForm`)"
                    class="button-radius font-14"
                  >
                    <span class="font-14"> Back </span>
                  </el-button>
                </div>

                <el-button
                  type="warning"
                  class="button-radius font-14"
                  native-type="submit"
                  :loading="loadingUpdate"
                  @click="$emit(`update`, form)"
                >
                  <span class="font-14"> Save </span>
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    infoCompany: {
      type: Object,
      default: {},
    },
    loadingUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  created() {
    this.setData();
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      optionsAuditCycle: (state) => state.optionAll.auditCycle,
      optionCalendar: (state) => state.calendarStatus,
      imgDoc: (state) => state.imgLogoDoc,
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      loadingSubmit: false,
      checkError: false,
      form: {},
      delCompany: [],
      setUpload: [],
      loading: false,
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  methods: {
    setData() {
      this.delCompany = [];
      this.setUpload = [];
      this.form = this.infoCompany;
      this.loading = true;
    },

    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (type[indexOfFile] != "json") {
        if (file.size < 25000000) {
          if (
            typeThis[indexOfFile] == "jpeg" ||
            typeThis[indexOfFile] == "png" ||
            typeThis[indexOfFile] == "jpg"
          ) {
            let obj = {
              name: file.name,
              file: file,
              url: file.url,
            };
            this.form.fileList.push(obj);
          } else if (
            typeThis[indexOfFile] == "pdf" ||
            typeThis[indexOfFile] == "docx" ||
            typeThis[indexOfFile] == "doc" ||
            typeThis[indexOfFile] == "xlsx" ||
            typeThis[indexOfFile] == "xls" ||
            typeThis[indexOfFile] == "csv" ||
            typeThis[indexOfFile] == "ppt" ||
            typeThis[indexOfFile] == "pptx" ||
            typeThis[indexOfFile] == "zip" ||
            typeThis[indexOfFile] == "txt"
          ) {
            if (typeThis[indexOfFile] == "pdf") {
              file.url = this.imgDoc.pdf;
            } else if (
              typeThis[indexOfFile] == "docx" ||
              typeThis[indexOfFile] == "doc"
            ) {
              file.url = this.imgDoc.docx;
            } else if (
              typeThis[indexOfFile] == "xlsx" ||
              typeThis[indexOfFile] == "xls" ||
              typeThis[indexOfFile] == "csv"
            ) {
              file.url = this.imgDoc.xlsx;
            } else if (
              typeThis[indexOfFile] == "ppt" ||
              typeThis[indexOfFile] == "pptx"
            ) {
              file.url = this.imgDoc.ppt;
            } else if (typeThis[indexOfFile] == "zip") {
              file.url = this.imgDoc.zip;
            } else if (typeThis[indexOfFile] == "txt") {
              file.url = this.imgDoc.txt;
            }
            this.form.fileList.push({
              name: file.raw.name,
              url: file.url,
              file: file,
            });
          } else {
            this.$message({
              message: "กรุณาเลือกไฟล์ให้ถูกต้อง",
              type: "error",
              duration: 4000,
            });
            let index = fileList.findIndex((a) => a.uid == file.uid);
            fileList.splice(index, 1);
          }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
      }
    },
    handleRemoveDocuments(file) {
      if (file._id != undefined) {
        this.form.delFile.push(file._id);
      }

      let index = this.form.fileList.findIndex((f) => f.uid == file.uid);

      this.form.fileList.splice(index, 1);
    },
    perviewFile(file) {
      if (file.raw == undefined && file.raw == null) {
        window.open(
          this.imageUrl +
            "calendar/" +
            this.form.assessmentPlanId +
            "/" +
            file.name
        );
      } else {
        window.open(URL.createObjectURL(file.raw));
      }
    },
  },
};
</script>
