<template>
  <div>
    <el-table
      :data="data"
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :class="data.length === 0 ? 'empty' : ''"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <img alt="logo" src="@/assets/logo.png" />
        <p>ไม่พบข้อมูล</p>
      </template>

      <el-table-column min-width="250" align="center" prop="companyName">
        <template slot="header">
          <div class="two-line">
            <p>Company name</p>
            <p>(ชื่อบริษัท)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="200"
        prop="address"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div class="two-line">
            <p>Address Of Client</p>
            <p>(ที่อยู่)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="180" align="center" prop="contactPerson">
        <template slot="header">
          <div class="two-line">
            <p>Contact person name</p>
            <p>(ชื่อผู้ติดต่อ)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="phone">
        <template slot="header">
          <div class="two-line">
            <p>Phone number</p>
            <p>(เบอร์โทร)</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column min-width="150" align="center" prop="standard">
        <template slot="header">
          <div class="two-line">
            <p>Standard</p>
            <p>(มารตฐาน)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.companyType.type == 1">
            {{
              mapDataOption(
                scope.row.companyType.standard,
                optionsStandardFood,
                "standard"
              )
            }}</span
          >
          <span v-else-if="scope.row.companyType.type == 2">
            {{
              mapDataOption(
                scope.row.companyType.standard,
                scope.row.companyType.company == 1
                  ? optionsStandardIsoOne
                  : optionsStandardIsoTwo,
                "standard"
              )
            }}
            {{
              scope.row.companyType.standard == 99
                ? `(${scope.row.companyType.other})`
                : ""
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        min-width="150"
        align="center"
        label="CAT No."
        prop="catNo"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.companyType.catNo.join(" ") }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="auditCycle">
        <template slot="header">
          <div class="two-line">
            <p>Audit cycle</p>
            <p>(รอบการตรวจติดตาม)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            class="color-error is-flex ai-center js-center"
            v-if="
              scope.row.expAuditCycle != '' &&
              scope.row.expAuditCycle != undefined &&
              checkExp(scope.row.expAuditCycle)
            "
          >
            <span class="mg-r-1">
              {{ scope.row.companyType.auditCycle }} เดือน
            </span>
            <el-tooltip placement="right-start">
              <div slot="content">
                รอบการตรวจติดตามครั้งถัดไป เดือน
                {{ returnMonthExp(scope.row.expAuditCycle) }}
              </div>
              <img class="img-info" alt="logo" src="@/assets/icon/info.svg" />
            </el-tooltip>
          </div>
          <span v-else> {{ scope.row.companyType.auditCycle }} เดือน </span>
        </template>
      </el-table-column>

      <el-table-column min-width="200" align="center" label="ผู้ตรวจ">
        <template slot-scope="scope">
          {{ scope.row.auditors.name + " " + scope.row.auditors.surname }}
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="auditors.phone">
        <template slot="header">
          <div class="two-line">
            <p>Phone number</p>
            <p>(เบอร์โทร)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="date">
        <template slot="header">
          <div class="two-line">
            <p>Date</p>
            <p>(วันที่)</p>
          </div>
        </template>

        <template slot-scope="scope">
          <span>{{ scope.row.date | formatDateTH }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="120" align="center" prop="catNo">
        <template slot="header">
          <div class="two-line">
            <p>Time</p>
            <p>(เวลา)</p>
          </div>
        </template>

        <template slot-scope="scope">
          <span>{{ scope.row.timeStart + " - " + scope.row.timeEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="140"
        align="center"
        label="วิธีการเข้าตรวจรับ"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.typeOfVisit == 1
              ? "Off-site"
              : scope.row.typeOfVisit == 2
              ? "On-site"
              : "Remote"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="140" align="center">
        <template slot="header">
          <div class="two-line">
            <p>Status</p>
            <p>(สถานะรอบการตรวจ)</p>
          </div>
        </template>

        <template slot-scope="scope">
          <span
            v-if="
              scope.row.statusChecking == undefined ||
              scope.row.statusChecking == ''
            "
            >{{ "-" }}</span
          >
          <span v-else>{{
            mapDataOption(
              scope.row.statusChecking,
              optionCalendar.statusChecking
            )
          }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="140" align="center">
        <template slot="header">
          <div class="two-line">
            <p>Customer Status</p>
            <p>(สถานะลูกค้า)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.customerStatus == undefined ||
              scope.row.customerStatus == ''
            "
            >{{ "-" }}</span
          >
          <span v-else>{{
            mapDataOption(
              scope.row.customerStatus,
              optionCalendar.customerStatus
            )
          }}</span>
        </template>
      </el-table-column>
      <el-table-column width="120" align="center" prop="more">
        <template slot="header">
          <div class="two-line">
            <p>Document</p>
            <p>(เอกสาร)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.files == undefined || scope.row.files == ''">{{
            "-"
          }}</span>
          <i
            class="fas fa-file-alt"
            v-else
            @click="openFiles(scope.row.assessmentPlanId)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        :show-overflow-tooltip="true"
        prop="more"
      >
        <template slot="header">
          <div class="two-line">
            <p>Note</p>
            <p>(เพิ่มเติม)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{
            scope.row.more == undefined || scope.row.more == ""
              ? "-"
              : scope.row.more
          }}</span>
        </template>
      </el-table-column>

      <el-table-column width="130px">
        <template slot="header">
          <div class="two-line">
            <p>Action</p>
            <p>(จัดการ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="box-icon">
            <i
              class="fas fa-eye"
              @click="
                $router.push(
                  `/matching/assessment-plan/detail/` +
                    scope.row.assessmentPlanId
                )
              "
            ></i>
            <i
              class="fas fa-edit"
              @click="$emit('editCalendarAuto', scope.row._id)"
            ></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import momentTH from "moment/src/locale/th";
import { HTTP } from "@/service/axios";

export default {
  computed: {
    ...mapState({
      _token: (state) => state._token,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      optionCalendar: (state) => state.calendarStatus,
      user: (state) => state.user,
    }),
  },
  props: {
    data: {
      default: Array,
      return: [],
    },
    loading: {
      type: Boolean,
      return: false,
    },
    page: {
      type: Number,
      return: 0,
    },
    typePage: {
      type: String,
      return: "",
    },
    limit: {
      type: Number,
      return: 0,
    },
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  methods: {
    openFiles(assessmentPlanId) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"]

      HTTP.get(`calendar/auto/export/doc/` + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            window.open(
              this.imageUrl +
                "calendar/" +
                assessmentPlanId +
                "/" +
                res.data.obj
            );
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    checkExp(date) {
      const today = new Date();
      const dateExp = new Date(date);
      var monthDiff =
        (dateExp.getFullYear() - today.getFullYear()) * 12 +
        (dateExp.getMonth() - today.getMonth());
      var isTwoMonthsEarlier = monthDiff <= 2;
      return isTwoMonthsEarlier;
    },
    returnMonthExp(date) {
      const dateExp = new Date(date);
      return moment(moment(dateExp).locale("th", momentTH)).format("MMMM Y");
    },
  },
};
</script>
