<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="calendar-page"
    class="auto"
  >
    <div>
      <h2 class="mg-y-2">Calendar</h2>
      <div class="is-flex js-end mg-y-5">
        <el-button
          round
          @click="openHistory()"
          class="mg-t-2 font-14 button-history"
          ><i class="fas fa-history"></i> History log</el-button
        >
      </div>

      <el-row :gutter="15">
        <el-col :span="24">
          <TableCalendarAuto
            :data="fetchData"
            :loading="loadingTable"
            @editCalendarAuto="editCalendarAuto"
          />
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="dialogCalendar"
      v-if="dialogCalendar"
      :before-close="closeForm"
      width="80%"
      :top="`8vh`"
    >
      <template slot="title">
        <h2 class="mg-y-1">
          <span> Calendar </span>
          >
          <span class="color-light-blue">Edit Calendar</span>
        </h2>
      </template>
      <el-form
        :model="formGet"
        :rules="rules"
        ref="ruleForm"
        @submit.native.prevent="submitEditCalendar"
        :label-position="`top`"
      >
        <DetailCalendarAuto
          :loadingUpdate="loadingUpdate"
          :dialogCalendar="dialogCalendar"
          :infoCompany="formGet"
          @closeForm="closeForm"
          @update="setCalendar"
        />
      </el-form>
    </el-dialog>
    <el-drawer
      class="drawer-history"
      :visible.sync="drawerHistory"
      :before-close="closeHistory"
    >
      <div class="box-header">
        <div>
          <span> History log </span>
        </div>
      </div>
      <div id="custom-collapse" v-if="fetchHistory.length > 0">
        <virtual-list
          v-if="fetchHistory.length > 0"
          class="detail-log list-infinite scroll-touch"
          :data-key="'_id'"
          :data-sources="fetchHistory"
          :data-component="itemComponent"
          v-on:tobottom="scrollToBottom"
          :item-class="'list-item-infinite'"
        >
          <div slot="footer" class="text-center" v-if="loadingHistory">
            <i class="el-icon-loading"></i>
          </div>
        </virtual-list>
      </div>
      <p
        class="font-20 color-75 text-center mg-y-5 pd-t-1"
        v-if="fetchHistory.length == 0"
      >
        ไม่มีข้อมูล
      </p>
    </el-drawer>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import TableCalendarAuto from "@/components/table/TableCalendarAuto";
import RequisitionDetail from "@/views/RequisitionDetail";
import VirtualList from "vue-virtual-scroll-list";
import History from "@/components/history/History";
import DetailCalendarAuto from "@/components/calendar/DetailCalendarAuto";
import { mapState } from "vuex";

export default {
  components: {
    TableCalendarAuto,
    RequisitionDetail,
    "virtual-list": VirtualList,
    DetailCalendarAuto,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      imgDoc: (state) => state.imgLogoDoc,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  mounted() {
    this.getCalendarAutoById();
  },
  data() {
    return {
      loading: true,
      loadingTable: false,
      loadingUpdate: false,
      fetchData: [],
      formGet: null,
      drawerHistory: false,
      fetchHistory: [],
      loadingHistory: false,
      dialogCalendar: false,
      totalAll: 0,
      skipHistory: 0,
      limitHistory: 10,
      pageNowHistory: 1,
      itemComponent: History,
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
      rules: {
        statusChecking: [
          {
            required: true,
            message: "กรุณาเลือกสถานะการตรวจ",
            trigger: "blur",
          },
        ],
        customerStatus: [
          {
            required: true,
            message: "กรุณาเลือกสถานะลูกค้า",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    getCalendarAutoById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`calendar/auto/` + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            this.fetchData = res.data.obj;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    searchAll() {
      let debounce = null;
      this.loadingTable = true;

      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.getRequisition();
      }, 600);
    },

    openHistory() {
      this.drawerHistory = true;
      this.getHistoryLog();
    },
    getHistoryLog(skip, limit, type) {
      let obj = {
        type: 5,
        assessmentPlanId: this.fetchData[0].assessmentPlanId,
        skip: skip != undefined ? skip : this.skipHistory,
        limit: limit != undefined ? limit : this.limitHistory,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/history/calendar`, obj)
        .then((res) => {
          if (res.data.success) {
            if (type != "scroll") {
              this.fetchHistory = res.data.obj;
            } else {
              res.data.obj.forEach((row) => {
                this.fetchHistory.push({ ...row });
              });
            }
            this.totalAll = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    closeHistory() {
      this.drawerHistory = false;
      this.skipHistory = 0;
      this.pageNowHistory = 1;
    },
    async scrollToBottom() {
      if (this.fetchHistory.length < this.totalAll) {
        this.skipHistory = this.pageNowHistory * this.limitHistory;
        this.loadingHistory = true;
        await this.getHistoryLog(this.skipHistory, this.limitHistory, "scroll");
        this.pageNowHistory++;
      } else {
        this.loadingHistory = false;
      }
    },
    async editCalendarAuto() {
      this.getCalendarAutoById();
      let getData = this.fetchData[0];
      let setUrlFile = "";
      let setFileList = [];
      if (getData.files.length > 0) {
        setFileList = getData.files.map((f, index) => {
          let typeThis = f.split(".");
          let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
          if (
            typeThis[indexOfFile] == "jpeg" ||
            typeThis[indexOfFile] == "png" ||
            typeThis[indexOfFile] == "jpg"
          ) {
            setUrlFile =
              this.imageUrl + "calendar/" + getData.assessmentPlanId + "/" + f;
          } else if (typeThis[indexOfFile] == "pdf") {
            setUrlFile = this.imgDoc.pdf;
          } else if (
            typeThis[indexOfFile] == "docx" ||
            typeThis[indexOfFile] == "doc"
          ) {
            setUrlFile = this.imgDoc.docx;
          } else if (
            typeThis[indexOfFile] == "xlsx" ||
            typeThis[indexOfFile] == "xls" ||
            typeThis[indexOfFile] == "csv"
          ) {
            setUrlFile = this.imgDoc.xlsx;
          } else if (
            typeThis[indexOfFile] == "ppt" ||
            typeThis[indexOfFile] == "pptx"
          ) {
            setUrlFile = this.imgDoc.ppt;
          } else if (typeThis[indexOfFile] == "zip") {
            setUrlFile = this.imgDoc.zip;
          } else if (typeThis[indexOfFile] == "txt") {
            setUrlFile = this.imgDoc.txt;
          }
          return {
            _id: f,
            name: f,
            url: setUrlFile,
          };
        });
      }

      let arr = {
        ...getData,
        auditorName: getData.auditors.name + " " + getData.auditors.surname,
        statusChecking: getData.statusChecking,
        customerStatus: getData.customerStatus,
        more: getData.more,
        companyType: {
          ...getData.companyType,
          catNo: getData.companyType.catNo.join(", ").toString(),
        },
        fileList: setFileList,
        delFile: [],
      };
      this.formGet = await arr;
      this.dialogCalendar = true;
    },
    setCalendar(data) {
      this.formGet = data;
    },
    submitEditCalendar() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // this.loadingUpdate = true;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "X-CSRF-TOKEN": this.setCsrf,
            },
          };
          var formData = new FormData();

          formData.append("_csrf", this.setCsrf);
          formData.append(
            "statusChecking",
            this.formGet.statusChecking == null ||
              this.formGet.statusChecking == ""
              ? ""
              : this.formGet.statusChecking
          );
          formData.append(
            "customerStatus",
            this.formGet.customerStatus == null ||
              this.formGet.customerStatus == ""
              ? ""
              : this.formGet.customerStatus
          );
          formData.append(
            "more",
            this.formGet.more == null || this.formGet.more == ""
              ? ""
              : this.formGet.more
          );
          if (this.formGet.fileList.length > 0) {
            this.formGet.fileList.forEach((e) => {
              if (e.file != undefined) {
                formData.append("files", e.file.raw);
                formData.append("filesName", e.file.name.toString());
              }
            });
          }
          if (this.formGet.delFile.length > 0) {
            this.formGet.delFile.forEach((delFile) => {
              formData.append("delFile", delFile);
            });
          } else {
            formData.append("delFile", this.formGet.delFile);
          }
          formData.append("assessmentPlanId", this.formGet.assessmentPlanId);
          HTTP.put(
            `calendar/auto/edit/` + this.$route.params.id,
            formData,
            config
          )
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  title: "แก้ไข Calendar สำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
                // this.$router.push(`/matching/assessment-plan`);
                this.closeForm();
                this.getCalendarAutoById();
              }
            })
            .catch((e) => {
              if (typeof e.response != "undefined") {
                if (e.response.data.status == 401) {
                  this.checkAuth();
                }
              }
            });

        }
      });
    },

    closeForm() {
      this.dialogCalendar = false;
      this.loadingUpdate = false;
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>
